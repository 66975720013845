import React from 'react';
import PropTypes from 'prop-types';

// Borrowed basic idea from https://github.com/yeutech-lab/react-cookiebot/blob/master/src/CookieBot.js
function CookieBot({
  domainGroupId = "8c06d546-2b8d-4345-b117-f211e3a343a1",
  language,
  renderCookieDeclaration = false
}) {
  if (typeof window === 'undefined') {
    return null;
  }
  if (!domainGroupId || !document) {
    return null;
  }
  if (domainGroupId.length !== 36) {
    console.warn(`The cookie bot domain group id is ${domainGroupId.length} characters, instead it should be 36 characters!`); // eslint-disable-line no-console
  }
  const existingScript = document.querySelector('#CookieBot');
  if (existingScript) {
    return null;
  }
  const script = document.createElement('script');
  script.setAttribute('id', 'CookieBot');
  script.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
  script.setAttribute('data-cbid', domainGroupId);
  script.setAttribute('data-blockingmode', 'auto');
  script.setAttribute('type', 'text/javascript');

  if (language) {
    script.setAttribute('data-culture', language);
  }
  const googleTagManagerScript = document.querySelector('[data-script="google-tag-manager"]');
  if (googleTagManagerScript) googleTagManagerScript.after(script);

  if (renderCookieDeclaration) {
    return (
      <script
        id="CookieDeclaration"
        src={`https://consent.cookiebot.com/${domainGroupId}/cd.js`}
        type="text/javascript"
        data-culture={language}
        async
      />
    );
  }

  return null
}

CookieBot.propTypes = {
  /** Cookie bot domain group id */
  domainGroupId: PropTypes.string,
  /** Cookie bot language */
  language: PropTypes.string,
  renderCookieDeclaration: PropTypes.bool
};

export default CookieBot;
